import React from "react";
import { Link } from "react-router-dom";

function Cards({ designer }) {
  return (
    <div className="col-12 col-md-4 p-3">
      <Link
        className="d-block designer-card h-100"
        to={`/designer-profile/${designer._id}`}
      >
        <div className="designer-card-image">
          <img
            className="w-100 rounded"
            src={designer.profileImg || "/assets/images/header-avatar.png"}
            style={{
              width: "100%",
              aspectRatio: "1 / .75",
              objectFit: "cover",
            }}
            alt=""
          />
        </div>
        <p className="designer-card-title text-wrap">{designer.name}</p>
        <p className="designer-card-body">{designer.bio}</p>
        {designer?.defineYourself && (
          <div
            className="mt-3 designer-card-badge"
            style={{ width: "fit-content" }}
          >
            <span className="">{designer?.defineYourself}</span>
          </div>
        )}
      </Link>
    </div>
  );
}

export default Cards;
